/* body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
} */
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
/* @import url("https://db.onlinewebfonts.com/c/55c4f556836775174bfac94178a3fe82?family=ProximaNW01-Reg"); */
.richText img {
  max-width: 100%;
}
a {
  display: inline-block;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px #3d4d6d inset !important;
  background-color: #3d4d6d !important;
  background-clip: content-box !important;
}
/* a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
} */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Microsoft JhengHei",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #210d1f;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
  pointer-events: none;
}
/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: "swap";
  src: url(https://fonts.gstatic.com/s/lato/v22/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: "swap";
  src: url(https://fonts.gstatic.com/s/lato/v22/S6uyw4BMUTPHjx4wXiWtFCc.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* @font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 100;
  src: url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Thin.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Thin.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Thin.otf)
      format("opentype");
}
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 300;
  src: url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Light.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Light.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Light.otf)
      format("opentype");
} */
/* @font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 400;
  src: url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.otf)
      format("opentype");
} */
/* @font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  src: url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Medium.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Medium.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Medium.otf)
      format("opentype");
} */
/* @font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Bold.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Bold.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Bold.otf)
      format("opentype");
} */
/* @font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 900;
  src: url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Black.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Black.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Black.otf)
      format("opentype");
} */
/* @font-face {
  font-family: "-apple-system Noto Sans TC";
  font-style: normal;
  font-weight: 100;
  src: url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Thin.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Thin.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Thin.otf)
      format("opentype");
  font-display: fallback;
} */
/* @font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 300;
  src: url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Light.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Light.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Light.otf)
      format("opentype");
  font-display: fallback;
} */
/* @font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 400;
  src: url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.otf)
      format("opentype");
  font-display: fallback;
}
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 600;
  src: url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Medium.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Medium.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Medium.otf)
      format("opentype");
  font-display: fallback;
}
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Bold.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Bold.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Bold.otf)
      format("opentype");
  font-display: fallback;
}
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 900;
  src: url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Black.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Black.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Black.otf)
      format("opentype");
  font-display: fallback;
} */
.block-img img {
  max-width: 100%;
}
p {
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-bottom: 12px;
}
ol {
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 24px;
}
ul {
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 24px;
  padding-bottom: 12px;
}
.iframeInput {
  /* background: #efefef; */
  -webkit-transition: background 200ms;
  transition: background 200ms;
  margin-top: 6px;
  margin-bottom: 6px;
  border: #888 1px solid;
  cursor: text;
  padding-left: 14px;
  padding-right: 14px;
  /* padding-top: 10.5px; */
  /* padding-bottom: 10.5px; */
  /* height: 16px; */

  height: 40px;
  border-radius: 4px;
}
.iframeInput:hover {
  border: #fff 1px solid;
}
.iframeInput.flex-microform-focused {
  /* background: lightyellow; */
  border: red 1px solid;
}
.highlight-row:hover {
  background-color: #333;
}

.tabButton:hover {
  background-color: #333;
}

select:invalid {
  color: gray;
}
/* #cardNumber.flex-microform-valid {
  background: green;
}
#cardNumber.flex-microform-valid.flex-microform-focused {
  background: lightgreen;
} */
.banner-img {
  opacity: 1;
  transition: opacity 1s;
  transition-delay: 0.5s;
}
.banner-img.hidden {
  opacity: 0;
  transition-delay: 0s;
}

body {
  margin: 0;
  font-family: Rubik, -apple-system, segoe ui, 微軟正黑體, microsoft jhenghei,
    sans-serif;
  /* background-color: #210d1f; */
  /* background-color: #121212; */
  /* scroll-padding-top: 4rem; */
  /* background-color: #420814; */
  /* line-height: 1; */
}
a {
  /* color: #f17341; */
  color: #fff;
  /* text-decoration: none; */
}

.App-header {
  /* background-color: #210d1f; */
  min-height: 100vh;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin); */
  /* color: white; */
  color: black;
}
.anchor {
  display: block;
  position: relative;
  top: -64px;
  visibility: hidden;
}
.disabledTrade {
  opacity: 0.2;
  pointer-events: none;
}
.border {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-color: #bebcba;
}
.border:hover {
  border-width: 2px;
  border-color: #fff;
}

/* for card (bundle, game) */
/* .card {
  transition: 0.5s ease;
}
.card:hover {
  transform: scale(1.05, 1.05);
} */
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #000000;
}
.overlay:hover {
  opacity: 0.5;
}

hr {
  border-color: #fff2;
  border-top: 0px;
}
li.nopadding {
  padding: 2px;
  padding-left: 0;
  /* line-height: 1; */
}
/* 
.App-link {
  color: #61dafb;
} */
/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
